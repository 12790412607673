export const environment = {
  production: true,
  apiurl: 'https://hvarest.omnicms3.nl:9002/api/v1/',
  mock: false,
  config: {
    product: "Mijn UvA-Pas",
    customer_fullname: "Universiteit van Amsterdam",
    customer_shortname: "UVA",
    url: "https://omnicard.nl/product/omnipas",
    phone: "+31 20 525 1403",
    email: "servicedesk-fs@uva.nl",
    MFAemail: false,
    MFAsms: false,
    logintype : "surf",
    autologin: false,
    username: "",
    password: "",
    samlConfig: {},
    surfConfig: { issuer: "https://connect.surfconext.nl"
                , clientId : "mijnuvapas.nl"
                , redirectUri: "https://www.mijnuvapas.nl/site/surflogin"
                , silentRefreshRedirectUri: "https://www.mijnuvapas.nl/site/silent-refresh.html"
                , logoutUrl: "https://engine.surfconext.nl/logout"
                , scope: "openid profile email"
                , loginItem: "uids"
                , oidc: true
                , skipPage: false
                , secret: "vWvHmaSMlMObowm2JugH"

                },
    adfsConfig: {},
    ingenico: {
      url: "https://secure.ogone.com/ncol/prod/orderstandard.asp",
      orderid: "CARD C.4201.0004 ",
      pspid: "UvAiDeal",
      sha_in_key: "ie!wufbw98234#342@234h238",
      sha_out_key: "@DJ@d32283d0j1j2233@39+",
      currency: "EUR",
      bgcolor: "#F3F3F3",
      txtcolor: "#004C93",
      tblbgcolor: "#FFFFFF",
      tbltxtcolor: "#666666",
      buttonbgcolor: "#004C93",
      buttontxtcolor: "#FFFFFF",
      fonttype: "Verdana",
      pm: "iDEAL",
      homeurl: "https://www.mijnuvapas.nl/site/payment",
      accepturl: "https://www.mijnuvapas.nl/site/payment/success",
      declineurl: "https://www.mijnuvapas.nl/site/payment/decline",
      exceptionurl: "https://www.mijnuvapas.nl/site/payment/fail",
      cancelurl: "https://www.mijnuvapas.nl/site/payment/cancel"
    },
    cardprices: { "Student": 1500, "Employee": 0, "External employee": 0, "default": 1500 },
    languages: [
      {
        id: 1,
        LanguageCode: "nl",
        LanguageName: "Nederlands",
        Card: "pas"
      },
      {
        id: 2,
        LanguageCode: "en",
        LanguageName: "English",
        Card: "pass"
      }

    ],
    nameoncardformats: [
      { id: 1, format: "<<Initials>> <<MiddleName>> <<LastName>>" },
      { id: 2, format: "<<FirstName>> <<MiddleName>> <<LastName>>" },
      { id: 3, format: "<<LastName>>, <<FirstName>> <<MiddleName>>" }
    ],
    savephotoonserver: false,
    changepublishpermission: false,
    chargingcard: false,
    digitalcard: false,
    uploadphoto: true,
    webcam: true,
    licenseplate: false,
    nameoncard: false,
    cardpreview: true,
    cardaslink: false,
    cardresult: true,
    cardactivate: true,
    cardblock: true,
    carddeblock: false,
    cardrequest: true,
    logoutbutton: false,
    requestnewpassword: false,
    publishPermission: false,
    scrollButtons: true,
    breadcrumbs: false,
    versioninfooter: false,
    photoResizeToWidth: 480,
    photoMiniatureWidth: 240,
    togglefont: false,
    photorequirements: true,
    firstnameonwelcome: false,
    photoAgreedNotOnFirstPage: true,
    deliveryaddress: true,
    langimage : false,
    maintainance: false,
    timeout: 180,
  },
  ssl: true
};
